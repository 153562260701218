.root {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
.rootExtended {
  grid-column-start: 1;
  grid-column-end: 3;
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);
  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.img {
  max-width: 100%;
}

.uploadAvatarInput {
  display: none;
}

.imageButtons {
  display: flex;
  gap: 16px;
}
