@import '../../../styles/customMediaQueries.css';

.root {
}

.infoText {
  composes: textSmall from global;

  display: flex;
  justify-content: flex-start;
  padding-left: 18px;
  background-position: left;
}
.verified {
  composes: infoText;
  color: var(--colorSuccess);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%232ECC71" fill-rule="evenodd"/></svg>');
}
.unverified,
.rejected {
  composes: infoText;
  color: var(--colorGrey300);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}
.pending {
  composes: infoText;
  color: var(--colorAttention);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23FA0" fill-rule="evenodd"/></svg>');
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.extraInfo {
  composes: textSmall from global;
  color: var(--colorGrey300);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.documents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  & > div {
    flex: 1;
    max-width: calc(50% - 8px);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--colorGrey700);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--colorGrey300);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.rejectionReason {
  display: flex;
  flex-direction: column;
  gap: 4px;

  background: var(--marketplaceColor);
  border-radius: 8px;
  padding: 8px 16px;
  color: var(--colorWhite);
  margin: 16px 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.bottomWrapper {
  margin-top: 46px;

  @media (--viewportMedium) {
    margin-top: 96px;
  }
}

.imageFromFile {
  object-fit: cover;
  overflow: hidden;
  background-color: var(--colorGrey100);
}

.hidden {
  position: absolute;
  visibility: hidden;
}
