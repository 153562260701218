.title {
  composes: marketplaceModalTitleStyles from global;
}

.messages {
  composes: marketplaceModalParagraphStyles from global;
}

.error {
  color: var(--colorAttention);
}

.buttons {
  composes: marketplaceModalBottomWrapper from global;
}

.button {
  composes: buttonSecondary from global;
}
